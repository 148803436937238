.about_p{
    color: #666666;
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    line-height: 2.8rem;
    text-align: justify;
}
.about_ol{
    color: #000000;
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    line-height: 2.8rem;
    text-align: justify;
}
.title.bold {
    padding: 15px 0;
    color: #000000;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 25px;
    text-align: left;
    border-bottom: 4px solid #000000;
    display: inline-block;
    margin-bottom: 20px;
}
/* new css */
/* mission css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

.hero-title {
  font-family: 'Roboto Mono', monospace; /* Techy font */
  font-size: 3rem;
  color: white;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.5), 0 0 40px rgba(0, 255, 255, 0.4), 0 0 50px rgba(0, 255, 255, 0.3), 0 0 60px rgba(0, 255, 255, 0.2); /* Neon glow effect */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgb(251, 255, 0), 0 0 20px rgba(251, 255, 0), 0 0 30px rgba(251, 255, 0), 0 0 40px rgba(251, 255, 0), 0 0 50px rgba(251, 255, 0), 0 0 60px rgba(251, 255, 0);
  }
  to {
    text-shadow: 0 0 20px rgba(251, 255, 0), 0 0 30px rgba(251, 255, 0), 0 0 40px rgba(251, 255, 0), 0 0 50px rgba(251, 255, 0), 0 0 60px rgba(251, 255, 0), 0 0 70px rgba(251, 255, 0);
  }
}

/* mission css end */
.sub{
    margin: 2rem auto;
}
.app-container {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(75%);
  }
  
  .hero-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #fff;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .main-content {
    padding: 3rem 0;
    /* background: #f9f9f9; */
  }
  
  .content-wrapper {
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;
  }
  
  .mission-statement {
    background-color: #d3a221;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .headings {
    font-size: 3rem;
    /* color: #333; */
    margin: 2rem auto;
    text-align: center;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: rgb(211, 162, 33);
  text-decoration-thickness: 4px;
    /* text-decoration-color: rgb(211, 162, 33); */
  }
  
  .objectives-list, .functions-list {
    list-style: none;
    padding: 0;
  }
  
  .objectives-list li, .functions-list li {
    background-color: #ffffff;
    margin-bottom: 1rem;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .objectives-list li:hover, .functions-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  }
 
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }
  /* bullet */
  