.bye-laws-container {
    /* width: 1000px; */
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(211, 162, 33);

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .nice-blue-button {
    background-color: #007bff; /* A nice shade of blue */
    color: #ffffff; /* White text color */
    border: none; /* No border */
    padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change mouse cursor to pointer */
    transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
    font-size: 16px; /* Text size */
    /* margin-right: 30px; */
    text-align: center; /* Center the text */
    display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
    text-decoration: none; /* No underline for text */
    /* Additional styles for better appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    outline: none; /* Remove outline on focus for aesthetics */
  }
  
  .nice-blue-button:hover, .nice-blue-button:focus {
    background-color: rgb(211, 162, 33);
    color: #fff; /* Darker shade of blue on hover/focus */
  }
  
  .page-header h1 {
    text-align: center;
    color: #007bff;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .bye{
    margin: 20px auto;
  }
  .page-content .section {
    margin-bottom: 30px;
  }
  
  .page-content h2 {
    color: #0056b3;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e3e3e3;
  }
  
  .page-content p {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .page-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .page-content li {
    color: #4a4a4a;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .bye-laws-container {
      margin: 20px;
      padding: 15px;
    }
  
    .page-header h1 {
      font-size: 24px;
    }
  
    .page-content h2 {
      font-size: 20px;
    }
  
    .page-content p, .page-content li {
      font-size: 14px;
    }
  }
  