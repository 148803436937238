.title_archive.bold {
    padding: 15px 0;
    color: #0b1538;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 25px;
    text-align: left;
    border-bottom: 4px solid #0b1538;
    display: inline-block;
    margin-bottom: 20px;
    text-align: center;
}

body {
    font-family: 'Montserrat';
    font-size: 2rem;
    background: #ffffff;
}
/* ArchiveData.css */
.articles-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    /* border-radius: 15px; */
  }
  
  .articles-table th,
  .articles-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  .articles-table th {
    background-color: #f2f2f2;
  }
  
  .articles-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .articles-table a {
    color: #007bff;
    text-decoration: none;
  }
  
  .articles-table a:hover {
    text-decoration: underline;
  }
  