.title_faq.bold {
    padding: 15px 0;
    color: #0b1538;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 25px;
    text-align: left;
    /* border: 1px solid rgb(211, 162, 33); */
    text-decoration: underline;
    text-decoration-color: rgb(211, 162, 33);
    text-decoration-thickness: 4px;
      display: inline-block;
    margin-bottom: 20px;
    text-align: center;
}



.content {
    width: 80%;
    padding: 20px;
    margin: 0 auto;
    padding: 0 60px 0 0;
}

.centerplease {
    margin: 0 auto;
    max-width: 270px;
    font-size: 40px;
}

.question {
    position: relative;
    background: #999;
    margin: 0;
    font-size: 1.7rem;
    padding: 10px 10px 10px 50px;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
}

.answers {
    background: #f2efef;
    padding: 0px 15px;
    margin: 5px 0;
    max-height: 0;
  
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
    -webkit-transition: .7s ease;
    -moz-transition: .7s ease;
    -o-transition: .7s ease;
    transition: .7s ease;
}

.questions:checked~.answers {
    max-height: 100%;
    opacity: 1;
    padding: 15px;
}

.plus {
    position: absolute;
    margin-left: 10px;
    z-index: 5;
    font-size: 1.7em;
    line-height: 139%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
}

.questions:checked~.plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.questions {
    display: none;
}

/* FAQ category CSS Start */
.faq__category__box {
    width: 330px;
    height: 400px;
    /* margin-top: 3rem; */
}

.faq__category__option {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 48px;
    background-color: #ffffff;
    border: 1px solid #d3a221;
    width: 339px;
    height: 55px;
    margin-top: 0.5rem;

}

.faq__category__option:hover,
.faq__category__option.selected {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 48px;
    background-color: #d3a221;
    width: 339px;
    height: 55px;
    margin-top: 0.5rem;
    cursor: pointer;
}



.faq__category__option img {
    margin-right: 1rem;
    margin-left: 1.5rem
}

.faq__category__option span {
    font-size: 1.7rem;
    color: #000000;
    font-weight: 600;
    font-family: "Montserrat";
}

.faq__category__option span:hover {
    font-size: 1.7rem;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
}

/* FAQ category CSS End */