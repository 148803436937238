.ask-our-lawyer-container {
  font-family: Arial, sans-serif;
  border: 5px solid rgb(211, 162, 33);
  border-radius: 15px;
  color: black;
  max-width: 1400px;
  margin: 20px auto;
}
.mb{
  margin-bottom: 50px;
}
.profile-section {
  margin-bottom: 12rem;
  align-items: center;
}

.profile-image-col {
  margin-bottom: 1rem;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink-button {
  animation: blink 1s linear infinite;
}
.profile-flex-container {
  display: flex;
  justify-content: space-between; /* Adjusts the space between the flex items */
  align-items: center; /* Centers items vertically */
}

.profile-details,
.appointment-button {
  flex: 1; /* Each child takes up equal amount of space */
}

/* Optional: Add some padding or margin if needed for spacing */
.profile-details {
  padding-right: 10px; /* Adjust as needed */
}

.appointment-button {
  padding-left: 10px; 
  margin-bottom: 20px;/* Adjust as needed */
}

.profile-image2 {
  width: 100%;
  /* max-width: 150px; */
  /* border-radius: 50%; */
}

.profile-info-col {
  text-align: left;
}

.profile-name2 {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.profile-title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.profile-description {
  font-size: 1.8rem;
  line-height: 1.5;
}

.service-section {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 0.5rem;
}

.service-description {
  font-size:1.8rem;
  margin-bottom: 0.5rem;
}

.service-timings {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}
.q-and-a-container {
  font-family: 'Times New Roman', serif;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.q-and-a-block {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 15px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.question2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.date-and-law {
  font-size: 2rem;
  /* color: lightblue; */
  margin-bottom: 10px;
  font-style: italic;
}

.answer {
  font-size: 2rem;
  text-align: justify;
  margin-bottom: 10px;
  line-height: 1.6;
  padding: 10px 20px;
  position: relative;
  background-color: gainsboro;
}

.answer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #0143f9; /* Assuming a light grey vertical line as seen in the image */
}

@media (max-width: 768px) {
  .profile-info-col, .service-section {
      text-align: center;
  }
}
