.dsc-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}
.border-container{
  border: 5px solid rgb(211, 162, 33);
border-radius: 15px;
}
.mb-cont{
  margin-bottom: 30px;
}
.dsc-card {
  flex: 0 0 32%;
  height: 200px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgb(211, 162, 33);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dsc-card2:hover{
  background-color: rgb(211, 162, 33);
  transition: background-color 0.3s ease;
  color: #fff;
}

.dsc-card .card-content {
  text-align: center;
  padding: 15px;
}
.dsc-btn{
  align-self: center;
}

.card-title {
  font-size: 2rem;
  font-weight: bold;
  color: black;
  transition: color 0.3s ease;
}

.dsc-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.dsc-card:hover .card-title {
  color: #fff;
}

@media (max-width: 767px) {
  .dsc-card {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}
.nice-blue-button {
  background-color: #007bff; /* A nice shade of blue */
  color: #ffffff; /* White text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change mouse cursor to pointer */
  transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
  font-size: 16px; /* Text size */
  /* margin-right: 30px; */
  text-align: center; /* Center the text */
  display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
  text-decoration: none; /* No underline for text */
  /* Additional styles for better appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  outline: none; /* Remove outline on focus for aesthetics */
}

.nice-blue-button:hover, .nice-blue-button:focus {
  background-color: rgb(211, 162, 33);
  color: #fff; /* Darker shade of blue on hover/focus */
}

.dsc-card2::before,
.dsc-card2::after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    z-index: -1;
}

.dsc-card2::before {
    top: -86px; /* -height/2 */
    border-bottom: 86px solid rgb(211, 162, 33);
}

.dsc-card2::after {
    bottom: -86px; /* -height/2 */
    border-top: 86px solid rgb(211, 162, 33);
}