.mbc-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    margin: 30px auto;
    max-width: 1200px; /* Adjust the max width as needed */
  }
  .mbc{
    padding-bottom: 2.5rem;
  }
  
  .mbc-card {
    flex: 0 0 32%; /* Adjust this percentage to manage spacing */
    height: 200px;
    margin-bottom: 30px; /* Spacing between rows */
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgb(211, 162, 33);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden; /* Ensures that content doesn't overflow the border-radius */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mbc-card:hover{
    background-color: rgb(211, 162, 33);
    transition: background-color 0.3s ease;
    color: white;
  }
  
  .mbc-card .card-content {
    text-align: center;
    padding: 15px;
  }
 .mbc-container{
    display: flex; /* Use flexbox to align items */
    justify-content: center; /* Center items horizontally */
    align-items: center;
    margin-bottom: 20px; /* Center items vertically (if the container has a defined height) */
 }

 

  .card-title {
    font-size: 2rem;
    font-weight: bold;
    color: black; /* Attractive blue color for the title */
    transition: color 0.3s ease;
  }
  
  .mbc-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .mbc-card:hover .card-title {
    color: #fff; /* Darker shade of blue for hover effect */
  }
  
  /* Media query for smaller screens to stack cards vertically */
  @media (max-width: 767px) {
    .mbc-card {
      flex: 0 0 100%; /* Full width on small screens */
      margin-bottom: 20px;
    }
  }
  .nice-blue-button {
    background-color: #007bff; /* A nice shade of blue */
    color: #ffffff; /* White text color */
    border: none; /* No border */
    padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change mouse cursor to pointer */
    transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
    font-size: 16px; /* Text size */
    /* margin-right: 30px; */
    text-align: center; /* Center the text */
    display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
    text-decoration: none; /* No underline for text */
    /* Additional styles for better appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    outline: none; /* Remove outline on focus for aesthetics */
  }
  
  .nice-blue-button:hover, .nice-blue-button:focus {
    background-color: rgb(211, 162, 33);
    color: #fff; /* Darker shade of blue on hover/focus */
  }
  