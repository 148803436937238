/* General Reset and Base Styles */




/* Navbar */
.blue__navbar {
  position: sticky !important;
  top: 0 !important;
  z-index: 500 !important; /* High z-index to ensure navbar stays on top */
  background-color: #0b1538 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 1rem 2rem !important; /* Increased padding */
  width: 100% !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

/* Sticky Navbar */
.sticky_navbar {
    position: sticky;
    top: 5rem; /* Adjust this value to the height of your blue navigation bar */
    z-index: 1000;
  background-color: #ffffff !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0.5rem 2rem !important;
  width: 100% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}
.logo {
    flex: 1; /* Give the logo container flexibility to adjust */
    display: flex;
    justify-content: center; /* Center logo horizontally */
    align-items: center; /* Center logo vertically */
    padding: 0 15px;
}
.logo img {
    display: block;
    max-height: 15rem; /* Adjusted for demonstration, set to your needed value */
    width: auto;
    visibility: visible;
    opacity: 1;
    position: relative;
    z-index: 501; /* This ensures the logo is above the navbar's content */
}

/* My Account section adjustments */

.my_account {
    width: 12.063rem;
    height: 8.563rem;
    z-index: 20; /* Ensure it has a higher z-index */
    position: relative; /* Confirm position is set to relative */
    margin-right: 15px;
    /* background-color: #01539d; */
    display: flex;
    align-items: center;
    justify-content: center;
}
button, .ant-btn {
    max-width: 100%; /* Prevent overflow */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
    white-space: nowrap; /* Prevent text wrapping */
    padding: 5px 10px; /* Adjust padding as necessary */
}

.content-container {
    overflow: hidden; /* Hide overflow */
}

/* Additional styles for specific button overflow issues */
.button-class-name { /* Replace '.button-class-name' with your actual button class */
    max-width: calc(100% - 30px); /* Adjust width considering padding */
    display: inline-block; /* Use inline-block for better control */
}
.card-hover-effect:hover {
  font-size: 14px;
  background-color: rgb(211, 162, 33);
  color: #fff;
}
/* Hover effect for the entire card */


/* Change text color of title and description on card hover */
.card-hover-effect:hover .meta_title,
.card-hover-effect:hover .meta_description {
  color: #fff; /* White text color on hover */
}
.submenu-item{
  text-decoration: none;
  font-size: 16px  !important;


}
.submenu-item:hover {
  font-size: 16px  !important;
  text-decoration: none;
  background-color: rgb(211, 162, 33) !important;
  color: #fff !important; /* Using !important to override any other styles */
}

/* Ensure links inside submenu items also inherit the hover color */
.submenu-item:hover .submenu-link {
  color: #fff !important; 
  text-decoration: none;/* Override link color on hover */
}


/* Mobile Navbar */
@media (max-width: 768px) {
    body{
        padding-top: 1rem !important;
    }
    .logo img {
        max-height: 3rem; /* Adjust logo size on smaller screens */
    }

    .my_account {
        margin-right: 10px; /* Adjust space for smaller screens */
    }
  
    .nav-img{
        height: 10vh;
    }
    .my_account{
display: block;  
margin: 20px ;  }
    .submenu-item {
      text-decoration: none;
      font-size: 16px; /* Maintain consistent font size */
      color: #000; /* Default text color for submenu items */
  }

  /* Hover effect for submenu items */
  .submenu-item:hover {
      background-color: rgb(211, 162, 33); /* Gold background color for hover state */
      color: #fff; /* White text color on hover */
      text-decoration: none;
  }

  /* Style links within submenu items to inherit hover styles */
  .submenu-item:hover a {
      color: inherit; /* Inherit the color from the parent .submenu-item on hover */
      text-decoration: none; /* No underline on hover */
  }
    .mobile-navbar{
        position: sticky;
        border-radius: 5px;
  border: 1px solid rgb(211, 162, 33);
  cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }
   
    .mobile-navbar .toggle-button {
        margin-left: auto;
        margin-right: 1rem;
    }
    /* extra */
    .mobile-navbar .logo_mobile {
        margin-left: 1rem;
        margin-right: auto;
    }
    .mobile-menu{
        width: 100%;
    }

    .mobile-menu button, .mobile-menu .ant-btn {
        max-width: 100%; /* Ensure buttons don't overflow their container */
    }
    /* extra end */
  .blue__navbar {
    justify-content: space-between !important;
    padding: 0.5rem 1rem !important;
  }

  .sticky_navbar {
    top: 3rem !important; /* Adjust top position when navbar is not displayed */
    padding: 0.5rem 1rem !important;
  }

  /* Toggling visibility based on viewport width */
  .navbar, .navbar-menu, .sticky_navbar .menu-item {
    display: none !important; /* Hide elements on mobile */
  }

  /* Mobile menu hamburger icon */
  .navbar-toggler {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 40px !important;
    height: 40px !important;
    background-color: #d3a221 !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  /* Responsive Search Bar */
  .navbar .search-bar {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    flex-grow: 1 !important;
  }

  .border-container{
      flex-direction: column;
  }
    .dosdonts-container{
      flex-direction: column;
  }

  /* Mobile Navbar Menu */
  .navbar-mobile {
    display: none !important; /* Hidden by default, toggled via JavaScript */
    flex-direction: column !important;
    position: absolute !important;
    top: 5rem !important; /* Below the navbar */
    left: 0 !important;
    width: 100% !important;
    background-color: #fff !important;
    padding: 1rem !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-top: 3px solid #d3a221 !important;
  }

  /* Mobile Navbar Menu Items */
  .navbar-mobile .menu-item {
    padding: 0.75rem 1rem !important;
    border-bottom: 1px solid #ddd !important;
  }

  /* Display mobile menu when active */
  .navbar-mobile.active {
    display: flex !important;
  }
}

/* Adjustments for smaller devices */
@media (max-width: 576px) {
  .blue__navbar {
    padding: 0.5rem !important; /* Less padding for very small screens */
  }

  .navbar-toggler {
    width: 35px !important;
    height: 35px !important; /* Adjusted size for hamburger icon */
  }
}

/* Ensure main content is not overlapped by the navbar */
/*body {*/
/*  padding-top: 6rem !important; !* Space for the fixed navbar *!*/
/*}*/

@media (min-width: 992px) {
  
}
/* blink effect of my account */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.my_account {
  animation: blink 1.5s infinite; /* Adjust the duration to control the speed of the blinking */
}
