.title_ec.bold {
    padding: 15px 0;
    color: #0b1538;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 25px;
    text-align: center;
    /* border-bottom: 4px solid #0b1538; */
    display: inline-block;
    margin-bottom: 20px;
}
p,a,h1,h2,h3,h4 {
	margin: 0;
	padding: 0;
}

.section-team {
	font-family: "Poppins", sans-serif;
	/* padding: 80px 0; */
}

.section-team .header-section {
	margin-bottom: 50px;
}

.section-team .header-section .small-title {
    margin-bottom: 25px;
	font-size: 16px;
    font-weight: 500;
    color: #d3a221;
}

.section-team .header-section .title {
    font-weight: 700;
    font-size: 45px;
}

.section-team .single-person {
	margin-top: 30px;
	padding: 30px;
	background-color: #f6f9ff;
	border-radius: 5px;
}

.section-team .single-person:hover {
	background: linear-gradient(to right, #e7c365, #fcf6e9);
}

.section-team .single-person .person-image {
    position: relative;
    margin-bottom: 50px;
    border-radius: 50%;
    border: 4px dashed transparent;
    transition: padding .3s;
}

.section-team .single-person:hover .person-image {
	padding: 12px;
    border: 4px dashed #fff;
}

.section-team .single-person .person-image img {
	width: 100%;
    border-radius: 50%;
}

.section-team .single-person .person-image .icon {
	position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,50%);
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: linear-gradient(to right, #016cec, #00b5f7);
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
}

.section-team .single-person:hover .person-image .icon {
	background: none;
	background-color: #fff;
	color: #d3a221;
}

.section-team .single-person .person-info .full-name {
	margin-bottom: 10px;
	font-size: 2.3rem;
    font-weight: 700;
    font-family: "Montserrat"
}

.section-team .single-person .person-info .speciality {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #016cec;
    font-family: "Montserrat"
}

.section-team .single-person:hover .full-name,
.section-team .single-person:hover .speciality {
	color: #0b1538;
    font-family: "Montserrat"
}