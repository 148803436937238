/* Terms.css */
.terms-container {
  background: #f4f4f9;
  padding: 40px;
  border: 5px solid rgb(211, 162, 33);
  border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.terms-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.term-section {
  background: white;
  border-left: 4px solid #007bff;
  margin-bottom: 2rem;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.term-section:hover {
  transform: translateY(-5px);
}

.term-section h2 {
  color: #007bff;
  margin-bottom: 1rem;
}
.li-p{
  margin: 15px auto;
}
.term-section p {
  /* color: #666; */
  line-height: 1.6;
}

.faCheckCircle, .faCogs, .faUserSecret {
  margin-right: 15px;
  color: #007bff;
  font-size: 1.5rem;
}
