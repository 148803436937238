@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap");
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.input-field{
    width: 85%;
    outline: 0;
    border: 0;
    border-bottom: 1px solid black;
    font-size: 28px;
    color:black;
    transition: 0.3s ease;
    font-family: Montserrat; 
    background-color:white
}
.input-fieldmessage{
    width: 93%;
    outline: 0;
    border: 0;
    border-bottom: 1px solid black;
    font-size: 28px;
    color:black;
    transition: 0.3s ease;
    font-family: Montserrat; 
    background-color: white
}

.mrgn{
    margin-bottom: 80px;
}

.d-md-flex .justify-content-md-center .btn .btn-dark{
    font-family: Montserrat;
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
    margin: 45px auto;
    background-color: #000;
    color: #fff;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 25px;
    cursor: pointer;
    border: none;
    border-radius: 10px;
  }
/* Media query */
@media only screen and (min-width: 3001px) and (max-width: 3900px) {
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .input-field{
        width: 85%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color:white
    }
    .input-fieldmessage{
        width: 93%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color: white
    }
    
    .mrgn{
        margin-bottom: 80px;
    }
    
    .d-md-flex .justify-content-md-center .btn .btn-dark{
        font-family: Montserrat;
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 50px;
        margin: 45px auto;
        background-color: #000;
        color: #fff;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 25px;
        cursor: pointer;
        border: none;
        border-radius: 10px;
      }
  }
  
  @media only screen and (min-width: 2401px) and (max-width: 3000px) {
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .input-field{
        width: 85%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color:white
    }
    .input-fieldmessage{
        width: 93%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color: white
    }
    
    .mrgn{
        margin-bottom: 80px;
    }
    
    .d-md-flex .justify-content-md-center .btn .btn-dark{
        font-family: Montserrat;
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 50px;
        margin: 45px auto;
        background-color: #000;
        color: #fff;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 25px;
        cursor: pointer;
        border: none;
        border-radius: 10px;
      }
  }
  
  @media only screen and (min-width: 1924px) and (max-width: 2400px) {
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .input-field{
        width: 95%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color:white
    }
    .input-fieldmessage{
        width: 98%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
     
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color: white
    }
    
    .mrgn{
        margin-bottom: 90px;
    }
    
    .d-md-flex .justify-content-md-center .btn .btn-dark{
        font-family: Montserrat;
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 50px;
        margin: 45px auto;
        background-color: #000;
        color: #fff;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 25px;
        cursor: pointer;
        border: none;
        border-radius: 10px;
      }
  }
  /* for input filed */
  @media only screen and (min-width: 364px) and (max-width: 767px) {
    .input-fieldmessage{
        width: 100%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
     
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color: white
    }
    .input-field{
        width: 100%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid black;
        font-size: 28px;
        color:black;
        transition: 0.3s ease;
        font-family: Montserrat; 
        background-color:white
    }
  }
  