@import url('https://fonts.googleapis.com/css2?family=Labrada:wght@600&family=Poppins:wght@300&display=swap');
.title-one {
    /* position: absolute; */
    width: 412px;
    height: 69px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 69px;
    text-transform: capitalize;
    color: #000000;
}
.title-two{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 42px;
    /* identical to box height */
    text-transform: capitalize;
    color: #000000;
}
@media (min-width: 363px) and (max-width: 480px){
    .title-one {
        /* position: absolute; */
        width: 412px;
        height: 69px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 69px;
        text-transform: capitalize;
        color: #000000;
    }
    .title-two{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 42px;
        /* identical to box height */
        text-transform: capitalize;
        color: #000000;
    }
}