/* Navbar CSS start */
.content-container {
    /* overflow-y: auto; */
    min-height: calc(120vh - 123px);
}


.embossed-icon {
    background-color: #f6f3ed;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center; /* Ensure items are centered inside the circle */
    align-items: center; /* Vertically center the content inside the circle */
    margin: 20px auto; /* Centers the icon horizontally */
    max-width: 100%; /* Ensures responsiveness */
    height: 60px; /* Fixed height for uniformity */
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.2); /* Inner shadow for embossed effect */
    border: 1px solid #e1dfda; /* Soft border for more depth */
    transition: transform 0.3s ease; /* Smooth transformation on hover */
}

.embossed-icon:hover {
    transform: scale(1.1); /* Slightly enlarge icons on hover for an interactive effect */
}


.logo {
    /* Update styles for logo */
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 20 !important;


}


.selected-card:hover {
    border-bottom: 3px solid #01539d;
}

/* Grey Navbar Styles */



.meta_title {
    font-size: 1.5rem;
    color: #1e1c1c;
    font-weight: 700;
    font-family: "Montserrat";
    text-align: center;
}

.meta_description {
    font-size: 12px;
    color: black;
    font-weight: 600;
    font-family: "Montserrat";
    text-align: left;
}

/* .my_account {
    width: 12.063rem;
    height: 3.563rem;
    background-color: #01539d;
    display: flex;
    align-items: center;
    justify-content: center;
    Center horizontally
} */
.Card:hover .embossed-icon{
    background-color: #d3a221;
    width: 100px;

}
.my_account p {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 700;
    font-family: "Montserrat";
    text-align: center;
}
.top-space{
    margin-top: 40px;
}

/* Search CSS start */
.ant-input-search .css-dev-only-do-not-override-1rqnfsa.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    margin-inline-end: -1px;
    padding-top: 0;
    padding-bottom: 0;
    border-start-start-radius: 0;
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
    border-end-start-radius: 0;
    box-shadow: none;

}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #02488f;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 4px;
    display: none;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-input-affix-wrapper>input.ant-input {
    padding: 0;
    font-size: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    background: transparent;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 400;
}

/* search CSS end */
/* navbar CSS end */



/* .logo_mobile img {
    height: 7rem;
} */

.toggle-button {

    background-color: #d3a221;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    /* Adjust width as needed */
    height: 3.5rem;
    /* Adjust height as needed */
}



@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Mobile Navbar CSS end */
/* new carousel change */
.screenwriters-layout {
    display: flex;
    height: 100vh; /* Adjust the height as necessary */
  }

  .carousel-column {
    /* Add styles for the carousel column */
    padding-right: 15px; /* Adjust spacing as necessary */
  }

  .options-column {
    /* Add styles for the options column */
    /* background-color: #1A1A1A; Or any color that matches your design */
    color: white;
    padding-left: 15px; /* Adjust spacing as necessary */
  }

  /* Additional styles for SwaCarousel and SwaOptionName if necessary */

/* Cards CSS Strat */
.card_title {
    font-size: 26px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 700;
    font-family: "Montserrat";
    text-align: center;
}

.card_description {
    font-size: 16px;
    line-height: 20px;
    color: #432819;
    font-weight: 500;
    font-family: "Montserrat";
    text-align: center;
}

.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* Center horizontally */
    text-align: center;
    /* Center text within the card */
    border-radius: 20px;
    border: 1px solid #d3a221;
    /* height: 100%; */
    height: 27rem;
}

.Card:hover {
    background-color: rgb(240, 240, 240);
    /* Change this to the desired grey color */
    transition: background-color 0.3s ease;
    /* Add a smooth transition effect */
}

.card_icon {
    display: flex; /* Change to 'flex' if you need to center it */
  margin: 20px auto; /* This centers the image in the block */
  max-width: 100%; /* This ensures the image is responsive and doesn't overflow */
  height: 40px;
}
.card-m{
    padding-top: 50px;
}
.card_description, .card_title{
    text-align: center;
}
.dsc-icon{
    /* margin: 10px auto; This centers the image in the block */

}
/* Cards mobile view css start */
.ant-card .ant-card-body {
    padding: 20px;
}


@media screen and (max-width: 767px) {
    .col-md-10 {
        display: block;
    }

    .responsive-card {
        width: 100%;
        margin-bottom: 1rem;
    }
}

/* cards mobile view css end */
/* Cards CSS end */

/* swa awards css start */
.black__box {
    max-width: 90% ;
    height: 86px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: 1.2rem;
    margin-right: 2rem;
    border: 1px solid #d3a221;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.black__box img {
    margin-right: 1rem;
    /* Adjust the margin as needed */
    margin-left: 1rem
}

.black__box span {
    font-size: 2.5rem;
    color: #d3a221;
    font-weight: 700;
    font-family: "Montserrat";
}
.a, .a:hover{
    text-decoration: none}
/* swa awards CSS end */
.hover-but:hover{
    background-color: #d3a221;
  transition: background-color 0.3s ease;
}
.hover-but:hover .meta_description{
    color: white;

}
.hover-but:hover .meta_title{
    color: white;

}
/* Options css start */
.blue__box {
    width: 100%;
    height: 409px;
    /* margin-top: 3re++m; */
}
.video-box{
    margin-left: 1rem;
    width: 90%;
    height:auto;
    border: 2px solid #d3a221;
    margin-bottom: 20px;/* Golden border */

}
/* watch our video */
.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
    gap: 20px;
}
.yellow__box {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center; /* Center text for all text-based children */
    /* Add any additional styling such as padding, background-color, etc. */
}


.yellow__box span {
    /* Style your title as needed */
    margin-top: 8px; /* Example space above the title */
}



.swa-award-h3{
    margin-left: 1rem;
    margin-top: 5px;
    font-style: italic;
    margin-bottom: 5px;
    font-size: 12px;
    text-align: left;
    color: #d3a221;
    font-weight: bold;
}
/* end */
.box__line {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 48px;
    background-color: #ffffff;
    border: 1px solid #d3a221;
    width: 90%;
    height: 58px;
    margin-left: 1.2rem;
    margin-top: 0.5rem;

}
.translate{
    margin-right: 20px;
}
.box__line:hover,
.box__line.selected {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 48px;
    background-color: #d3a221;
    color: white;
    margin-left: 1.2rem;
    margin-top: 0.5rem;
    cursor: pointer;
}



.box__line img {
    margin-right: 1rem;
    margin-left: 1.5rem
}

.box__line span {
    font-size: 1.7rem;
    color: #000000;
    font-weight: 600;
    font-family: "Montserrat";
}
.footer__screenwriters ul {
    padding-left: 20px; /* Align bullets */
    margin: 0; /* Remove default margin */

}

.footer__screenwriters li {
    margin-bottom: 10px;

    /* Space between list items, adjust as needed */
}

.footer__screenwriters a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fff;/* Remove underline from links */
}
.footer__screenwriters a:hover {
    text-decoration: none;
    font-size: 1.5rem;
    /* Remove underline from links */
    color: blue; /* Keep link color consistent with your design */
}
.box__line {
    position: relative; /* Needed for absolute positioning of the dropdown */
}

.dropdown-content {
    display: none; /* Hide dropdown content by default */
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 140px;
}

.box__line:hover .dropdown-content {
    display: block;
    color: black; /* Show dropdown content on hover */
}

.dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-item:hover {
    background-color: #d3a221;
    color: #ffffff;
}

.box__line span:hover {
    font-size: 1.7rem;
    color: #ffffff;
    font-weight: 600;
    font-family: "Montserrat";
}
.welcome__section {
  /* Dark navy background */
    display: flex;
    width: 96%;
    flex-direction: column;
    align-items: center;
    padding-top: 20px; /* Adjust padding as needed */
    text-align: left; /* Center the text */
  }
  .gallery-video-row {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
    margin-left: -5px; /* Negative margin to counteract the padding on the child divs */
    margin-right: -5px; /* Same as above */
  }
  
  .gallery-video-row > div {
    padding-left: 5px; /* Adds padding inside the child divs for spacing */
    padding-right: 5px; /* Same as above */
    flex-basis: calc(50% - 10px); /* Calculates width for each div, subtracting padding */
    flex-grow: 1; /* Allows divs to grow to fill extra space if available */
    box-sizing: border-box; 
    margin-bottom: 20px;/* Includes padding in the width calculation */
  }
  
  /* Media query for smaller screens, adjust the breakpoint as needed */
  @media (max-width: 768px) {
    .gallery-video-row > div {
      flex-basis: 100%; /* Stacks divs on smaller screens */
    }
  }
  
  /* Additional styles for the welcome section if needed */
  .welcome__section h1,
  .welcome__section h2 {
    margin: 0;
    font-style: italic;
    /* Set color if needed */
  }
  
  .welcome__section h1  {
    color:#467fb3;
   }
  .welcome__section hr {
    border: 0; /* Remove default border */
    height: 2px; /* Set the height of the line */
    background-color: #d3a121; /* Use the gold color for the line */
    margin: 20px 0 0 0;
    /* Add some space around the line */
  }

/* options css end */

/* slider css start */
.slider__box {
    margin-top: 3rem;
}

.slider__box img {
    width: 100%;
    height: 500px; /*384px
    /* margin: auto 5px; */
    border: 1px solid #d3a121;
    border-radius: 20px;
}
.Card .embossed-icon:hover {
    background-color: #d3a121;
}
/* .embossed-icon:hover img{
    content: url('../../../public/Web_Photo_Editor.jpg');
} */
.image-title {
    position: absolute;
    bottom: 0;
    left: 50%; /* Align the left edge of the title with the center of the image */
    transform: translateX(-50%); /* Move the title horizontally by half of its width to center it */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    color: white;
    padding: 5px 10px; /* Adjust padding as needed */
    font-size: 14px; /* Adjust font size as needed */
}


@media (max-width: 768px) {
    .slider__box img {
        height: 100%;
        width: 100%;
    }
}

.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align icon to the left */
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: transparent;
    /* Set background to transparent */
    border: none;
    /* Remove border */
    opacity: 0.5;
    /* Adjust opacity as needed */
    transition: opacity 0.15s ease;
}

.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Align icon to the left */
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: transparent;
    /* Set background to transparent */
    border: none;
    /* Remove border */
    opacity: 0.5;
    /* Adjust opacity as needed */
    transition: opacity 0.15s ease;
}

.carousel-control-prev-icon {
    background-image: url('../../../public/Rectangle 8 copy (2) (1).png');

}

.carousel-control-next-icon {
    background-image: url('../../../public/nextslider.png');
}


/* slider css end */

/* Photo gallery CSS Start */
.photo_gallery p {
    font-size: 18px;
    line-height: 45px;
    color: #000000;
    font-weight: 700;
    font-family: "Montserrat";
    margin-left: 1rem;
    margin-top: 1.5rem;

}


.photo_gallery .box {
    width: 90%;
    height:auto;
    margin-left: 1rem;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid #d3a221; /* Golden border */
    /* border: 1px solid #d3a121; */
}

.photo_gallery .box img {
    width: 100%;
    height: 186px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    object-fit: cover;

    /* border: 1px solid #d3a121; */
}

.photo_gallery span {
    font-size: 15px;
    justify-content: right;
    align-items: right;
    margin-left: 11rem;
}

/* Photo Gallery CSS end */

/* Video Section CSS Start */
.video_section p {
    font-size: 18px;
    line-height: 45px;
    color: #000000;
    font-weight: 700;
    font-family: "Montserrat";
    margin-left: 1rem;
    /* margin-top: 1.5rem; */

}

.video_section .box {
    width: 313px;
    height: auto;
    margin-left: 1rem;
    border-radius: 5px;
    background-color: #ffffff;
}
.head3{
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    margin-bottom: 15px;
}
.video_section .box img {
    width: 313px;
    height: 186px;
    border-radius: 5px;
    background-color: #ffffff;
}

.video_section .box .video {
    width: 313px;
    height: 186px;
    border-radius: 5px;
}

/* Video section CSS end */

/* Footer CSs strat */
.copyright {
    font-size: 14px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Montserrat";
}

.footer__social {
    font-size: 14px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Montserrat";
}

.footer_text_1 {
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 300;
    font-family: "Montserrat";
    margin-top: 1.5rem;
}

.footer_text_header {
    font-size: 20px;
    line-height: 36px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Montserrat";
    margin-bottom: 2.5rem;

}

.col P {
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 300;
    font-family: "Montserrat";
    margin-left: 0.5rem;
}

.footer__screenwriters {
    width: 100%;
    min-height: 362px;
    background-color: #0b1538;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 3rem; nidhi */
}

/* footer css end */

/* .carousel-control-next-icon{
    background-image: url('/public/');
} */
.mobile-menu a {
    text-decoration: none;
}
/* nav res */


@media screen and  (min-width: 768px){
    .col-md-4{
        width: 27.66%;
        margin-right: 4.66%;
        margin-bottom: 3.5rem;
    }
}
/* here is embosed effect */
.Card {
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for interaction */
    border: 1px solid #e0e0e0; /* Subtle border */
    box-shadow: inset 0px 4px 6px rgba(255, 255, 255, 0.3), /* Inner white shadow for the top */
                inset 0 -4px 6px rgba(0, 0, 0, 0.2); /* Inner dark shadow for the bottom */
    border-radius: 10px; /* Rounded corners for a softer look */
  }
  
  .Card:hover {
    transform: translateY(-5px); /* Slightly raise the card on hover */
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.6), /* Lighten the inner top shadow on hover */
                inset 0 -2px 4px rgba(0, 0, 0, 0.1), /* Lighten the inner bottom shadow on hover */
                0 4px 6px rgba(0, 0, 0, 0.1); /* Outer shadow for depth */
  }
  