/* AppointmentPage.css */

.appointment-container {
    max-width: 1400px;
    margin: 30px auto;
    padding: 20px;
  }
  
  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
  }
  
  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .appointment-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .appointment-form input,
  .appointment-form textarea {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .appointment-form button {
    cursor: pointer;
    padding: 10px;
  }
  