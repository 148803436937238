.tabs-container {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    padding-bottom: 1px; /* Ensures there is a slight gap under the active tab */
}

.tab {
    margin-right: 10px;
    flex-grow: 1; /* Ensures tabs grow to use available space */
}

.tab-input {
    display: none;
}

.tab-label {
    padding: 12px 20px;
    background-color: #fff;
    border: 2px solid #d3a221;
    border-bottom: 2px solid #d3a221; /* Prevents double border at the bottom */
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px 8px 0 0; /* Rounds top corners only */
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center; /* Ensures text is centered in the tab */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.tab-label:hover, .tab-label.active {
    background-color: #d3a221;
    color: white;
    border-bottom-color: #fff; /* Maintains the background color on hover/active */
    transform: translateY(-2px);
}

.tab-content {
    display: none;
    padding: 20px;
    border: 2px solid #d3a221;
    border-top: none; /* Avoid double border with tabs */
    background: #fff;
    margin-top: -2px; /* Pulls content up to cover any gap under the active tab */
}

.active .tab-content {
    display: block;
}

@media (max-width: 768px) {
    .tabs-container {
        flex-direction: column;
        margin: 20px 0;
    }

    .tab {
        margin-right: 0;
    }

    .tab-label {
        margin-bottom: 2px; /* Adds space between tabs on smaller screens */
    }
}
