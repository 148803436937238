.welfare-container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .welfare-title {
    text-align: center;
    color: #0056b3;
    margin-bottom: 30px;
  }
  
  .scheme-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
  }
  
  .scheme-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  
  .icon {
    min-width: 50px;
    min-height: 50px;
    margin-right: 20px;
    color: #0056b3;
    font-size: 24px;
  }
  
  .scheme-section h3 {
    margin: 0;
    /* color: #333; */
  }
  
  .scheme-section p {
    margin: 10px 0 0 0;
    /* color: #666; */
  }
  
  welf-ul {
    padding-left: 20px;
  }
  
  .contact-info {
    text-align: center;
    margin-top: 40px;
  }
  
  .contact-info a {
    color: #0056b3;
    text-decoration: none;
  }
  /* General paragraph styling */
.welfare-container p {
    font-size: 16px; /* Adjust the font size as needed */
    line-height: 1.6; /* Adjust line height for readability */
    color: #333; /* Adjust the text color as needed */
    margin-bottom: 20px; /* Space below paragraphs */
  }
  
  /* Strong tag within paragraphs for emphasis */
  .welfare-container p strong {
    color: #0056b3; /* A darker shade for emphasis, adjust as needed */
    font-weight: bold; /* Make it bold */
  }
  
  /* General unordered list styling */
  .welfare-container ul {
    list-style-type: disc; /* Style of the bullet points */
    padding-left: 20px; /* Indentation for the list */
    margin-bottom: 20px; /* Space below the list */
  }
  
  /* List item styling */
  .welfare-container li {
    font-size: 16px; /* Adjust the font size as needed */
    line-height: 1.6; /* Line height for readability */
    /* color: #555; Text color for list items */
  }
  
  /* Nested lists for more specific items */
  .welfare-container ul ul {
    list-style-type: circle; /* Different style for nested list items */
    margin-top: 10px; /* Space above nested list */
  }
  
  /* Styling for headings within the Welfare component */
  .welfare-container h2, .welfare-container h3, .welfare-container h4 {
    color: #0b1538; /* Adjust heading colors */
    margin-top: 30px; /* Space above headings */
    margin-bottom: 15px; /* Space below headings */
  }
  
  /* Specific section styling */
  .scheme-section {
    background-color: #f8f9fa; /* Light background for sections */
    border-radius: 5px; /* Rounded corners */
    padding: 20px; /* Padding inside sections */
    margin-bottom: 20px; /* Space below sections */
  }
  
  /* Icons within sections */
  .scheme-section .icon {
    font-size: 24px; /* Icon size */
    color: #0056b3; /* Icon color */
    margin-right: 10px; /* Space after the icon */
  }
  
  /* Contact information styling */
  .contact-info {
    font-style: italic; /* Italicize contact info */
  }
  
  /* Link styling within the Welfare component */
  .welfare-container a {
    color: #0056b3; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .welfare-container a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  
  @media (max-width: 768px) {
    .scheme-section {
      flex-direction: column;
      align-items: start;
    }
  
    .icon {
      margin-bottom: 10px;
    }
  }
  