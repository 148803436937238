/* Base Styles */
.swa-membership-container {
  max-width: 1400px;
  margin: 20px auto;
  padding: 2rem;
  border: 3px solid rgb(211, 162, 33); /* Thinner border for a less heavy look */
  border-radius: 10px; /* Softer roundness */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for depth */
  background: #fff; /* Consider a background to ensure readability */
}

.swa-membership-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Increased gap for better separation */
}

.swa-membership-notes,
.swa-membership-documents {
  padding: 1.5rem; /* More internal space */
  border: 1px solid #eaeaea; /* Lighter border */
  border-radius: 8px; /* Consistent with container */
  background: #fafafa; /* Slight background contrast */
}

/* List Styles */
.swa-membership-notes ol,
.swa-membership-documents ul {
  list-style-position: inside; /* Position bullets inside to align text */
}

.head {
  font-size: 1.25rem; /* More pronounced size */
  color: #333; /* Darker for emphasis */
  margin-bottom: 0.5rem; /* Space between heading and content */
}

.memberli {
  margin: 10px 0; /* Reduced margin for compactness */
}

/* List Items */
.swa-membership-notes li,
.swa-membership-documents li {
  display: flex; /* Align icons with text */
  align-items: center; /* Center items vertically */
  gap: 0.5rem; /* Space between icon and text */
  padding: 0.5rem 0; /* Padding for each list item */
}

/* Strong Text */
.swa-membership-notes li strong,
.swa-membership-documents li strong,
.swa-membership-declaration strong {
  color: #444; /* Slightly softer than black */
}

/* Links */
.swa-membership-notes li a,
.swa-membership-documents li a {
  color: #0056b3; /* More traditional link color */
  text-decoration: underline; /* Underline to indicate clickable */
}

/* Even Items */
.swa-membership-documents li:nth-child(even) {
  background-color: #f5f5f5; /* Zebra striping for differentiation */
}

/* Icons */
.swa-membership-documents li svg {
  color: #4CAF50; /* Brighter green for visibility */
  margin-right: 8px; /* Space before text */
}

/* Responsive Adjustments for Tablets */
@media (max-width: 1024px) {
  .swa-membership-container,
  .new-container,
  .swa-membership-declaration {
    padding: 1rem;
  }

  .swa-membership-notes,
  .swa-membership-documents {
    padding: 1rem;
  }

  .swa-membership-content {
    gap: 1rem;
  }
}
.nice-blue-button {
  background-color: #007bff; /* A nice shade of blue */
  color: #ffffff; /* White text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change mouse cursor to pointer */
  transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
  font-size: 16px; /* Text size */
  /* margin-right: 30px; */
  text-align: center; /* Center the text */
  display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
  text-decoration: none; /* No underline for text */
  /* Additional styles for better appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  outline: none; /* Remove outline on focus for aesthetics */
}

.nice-blue-button:hover, .nice-blue-button:focus {
  background-color: rgb(211, 162, 33);
  color: #fff; /* Darker shade of blue on hover/focus */
}

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
  .swa-membership-content {
    flex-direction: column;
  }

  .swa-membership-container,
  .new-container,
  .swa-membership-declaration {
    padding: 0.75rem;
  }

  .swa-membership-notes,
  .swa-membership-documents,
  .swa-membership-notes li,
  .swa-membership-documents li {
    padding: 0.5rem;
    font-size: 1.5rem; /* Slightly reduced font size */
  }
  .nice-blue-button{
    display: block;
    margin: 20px ;
  }
  .h1, .h2, .h3 {
    font-size: 1rem; /* Adjust heading sizes for mobile */
  }
}

/* Additional Styles */
.new-container,
.h1, .h2, .h3, .p, .note, .li {
  margin: 0 auto;
  padding: 1rem; /* Consistent padding */
  color: #333; /* Standard text color */
}

.h1, .h2, .h3 {
  margin-top: 1rem; /* Space above headings */
  margin-bottom: 0.5rem; /* Space below headings */
}

.h1 {
  font-size: 2.5rem; margin: 30px auto;
  font-weight: bold;
  text-decoration: underline;/* Larger for main headings */
}

.h2 {
  font-size: 1.9rem; /* Secondary headings */
  border-bottom: 2px solid #ddd; /* Subtle underline */
}
.nice-blue-button {
  background-color: #007bff; /* A nice shade of blue */
  color: #ffffff; /* White text color */
  border: none; /* No border */
  padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change mouse cursor to pointer */
  transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
  font-size: 16px; /* Text size */
  /* margin-right: 30px; */
  text-align: center; /* Center the text */
  display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
  text-decoration: none; /* No underline for text */
  /* Additional styles for better appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  outline: none; /* Remove outline on focus for aesthetics */
}

.nice-blue-button:hover, .nice-blue-button:focus {
  background-color: rgb(211, 162, 33);
  color: #fff; /* Darker shade of blue on hover/focus */
}

.h3 {
  font-size: 1.25rem; /* Tertiary headings */
}

.p {
  line-height: 1.6; /* Improved readability */
  margin-bottom: 1rem; /* Space after paragraphs */
}

.note {
  background-color: #f9f9f9; /* Softer background for notes */
  border-left: 4px solid rgb(33, 77, 211); /* Highlight line */
  padding: 1rem; /* Internal spacing */
  margin-top: 1rem; /* Space above */
  font-style: italic; /* Distinguish notes */
}
