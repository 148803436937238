@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

:root{
  font-size: 62.5%;
}

.maindiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.for-work-nav h1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";
  font-size: 7rem;
  line-height: 98px;
  letter-spacing: 0.1em;
  font-variant: small-caps;
  color: black;
  padding-left: 25px;
}

.for-index-nav {
  display: flex;
}

.for-index-nav h1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";
  font-size: 5rem;
  line-height: 39px;
  letter-spacing: 0.1em;
  font-variant: small-caps;
  padding: 0 25px;
  color: #000000;
}
/* .for-index-nav h1:nth-of-type(2) a {
  position: relative;
  animation: slide 0.8s forwards;
}
@keyframes slide {
  0% {
      left: 0;
      opacity: 1;
  }
  100% {
      left: 100%;
      opacity: 0;
  }
} */
.for-work-nav h1 {
  transition: all 0.3s ease-in-out;
}

.for-work-nav h1.hide {
  transform: translateX(-100%);
  opacity: 0;
}
.for-work-nav i {
  padding-left: 2rem;
  font-size: 3.6rem;
}








/* navbar animated*/

/* Workdata Page CSS */
.maindivforimage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  width: 500px;
  height: 500px;
}

.maindivforimage img {
  width: 100%;
}

.col .content img {
  width: 330px;
}

.content {
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(100% - 2em);
  margin: 0.5em 0.5em 1.5em;
}

.content:hover:after {
  font-family: "Montserrat", sans-serif;
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 700;
  font-style: normal;
  content: attr(data-legend);
  font-variant: small-caps;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  transform: translateY(100%);
}

.for-about-us p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  font-size: 1.6rem;
  line-height: 3rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  padding: 0 5%;
  /* place-items: center; */
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.grid-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body h5 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 5rem;
}

.card-body p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  font-size: 4rem;
}

.for-about-us-padding {
  padding: 2rem 5rem;
}
/* @media (min-width: 577px) and (max-width: 768px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .for-work h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 20px;
  
    font-variant: small-caps;
    color: #000000;
    
  }
  .for-index h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 14px;
  }
}
@media (min-width: 460px) and (max-width: 576px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .for-work h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 16px;
    
    font-variant: small-caps;
    color: #000000;
    
  }
  .for-index h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 39px;
    
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 14px;
  }
}
@media (min-width: 373px) and (max-width: 459px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .for-work h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 12px;
   
    font-variant: small-caps;
    color: #000000;
  }
  .for-index h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 39px;
  
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 12px;
  }
}
@media (min-width: 300px) and (max-width: 372px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .for-work h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 10px;
  
    font-variant: small-caps;
    color: #000000;
  
  }
  .for-index h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 39px;
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 12px;
  }
} */


/* Media query */
@media only screen and (min-width: 3001px) and (max-width: 3900px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 2rem;
    font-size: 10rem;
  }

  .for-work-home img {
    width: 440px;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 13rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    /* padding: 0 25px; */
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 5rem;
    line-height: 8rem;
  }
  .for-about-us-padding {
    padding: 10rem 5rem;
  }
}

@media only screen and (min-width: 2401px) and (max-width: 3000px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 408px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 2rem;
    font-size: 8.5rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 11rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 9rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    /* padding: 0 25px; */
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 4rem;
    line-height: 7rem;
  }
  .for-about-us-padding {
    padding: 7rem 5rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 4rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2400px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 370px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 2rem;
    font-size: 7rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 9rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 7rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    /* padding: 0 25px; */
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 4rem;
    line-height: 7rem;
  }
  .for-about-us-padding {
    padding: 2rem 5rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 3.5rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1900px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 330px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 2rem;
    font-size: 5.4rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 7rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    /* padding: 0 25px; */
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 3rem;
    line-height: 5rem;
  }
  .for-about-us-padding {
    padding: 2rem 5rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 3rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1600px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 2rem;
    font-size: 3.8rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 5rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    /* padding: 0 25px; */
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 2rem;
    line-height: 4rem;
  }
  .for-about-us-padding {
    padding: 2rem 5rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.5rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 1.3rem;
    font-size: 3.2rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 4rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    padding: 0 20px;
    color: #000000;
  }

  .for-work-home img {
    display: flex;
    align-items: center;
    top: 10px;
  }

  .for-work-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 4rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }

  .for-index-home {
    display: flex;
  }

  .for-index-home h1 {
    /* font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 2.9rem;
    line-height: 39px;
    letter-spacing: 0.1em;
    padding: 0 20px;
    font-variant: small-caps;
    color: white; */
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9rem;
    /* line-height: 39px; */
    /* letter-spacing: 0.1em; */
    padding: 0 20px;
    color: w;
  }

  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 2rem;
    line-height: 4rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.8rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 1.4rem;
  }
  .for-about-us-padding {
    padding: 2rem 5rem;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 170px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 1.3rem;
    font-size: 2.3rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 3rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.9rem;
    padding: 0 15px;
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 1.5rem;
    line-height: 3rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 1.2rem;
  }
}

@media (min-width: 460px) and (max-width: 576px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 130px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 1.3rem;
    font-size: 1.7rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 2.2rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 39px;
    /* letter-spacing: 0.1em; */
    padding: 0 15px;
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 1.2rem;
  }
}

@media (min-width: 373px) and (max-width: 459px) {
  .maindiv {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }

  .for-work-home img {
    width: 110px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 1.3rem;
    font-size: 1.4rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 1.6rem;

    font-variant: small-caps;
    color: #000000;
  }
  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 39px;
    padding: 0 10px;
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 1rem;
    line-height: 2rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 1.2rem;
  }
}

@media (min-width: 300px) and (max-width: 372px) {
  .maindiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-work-home img {
    width: 90px;
  }

  .for-work-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .for-work-nav i {
    padding-left: 0.8rem;
    font-size: 0.8rem;
  }

  .for-work-nav h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 0.9rem;
    /* line-height: 98px; */
    /* letter-spacing: 0.1em; */
    font-variant: small-caps;
    color: #000000;
    /* padding-left: 25px; */
  }

  .for-index-nav h1,
  .for-index-home h1 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 39px;
    padding: 0 10px;
    color: #000000;
  }
  .for-about-us p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 1rem;
    line-height: 2rem;
  }

  .for-about-us-padding {
    padding: 1rem 2rem;
  }
  .card-body h5 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.3rem;
  }

  .card-body p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 1rem;
  }
}

/* -----------------media query for work page image---------------------- */

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding: 0 5%;
    /* place-items: center; */
  }

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }

  .grid-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .img-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding: 0 5%;
    /* place-items: center; */
  }

  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }

  .grid-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .img-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
