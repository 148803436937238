.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .para{
    margin: 15px auto;

  }
  .heading{
    /* font-weight: bold; */
    margin: 20px auto;
    font-size: 30px;
  }
  .heading2{
    margin: 10px auto;
    font-size: 14px;
  }
  .heading3{
    margin: 5px auto;
    font-size: 14px;
    font-weight: 300;
  }
  .heading4{
    margin: 5px auto;
    font-size: 14px;
    font-weight: 600;
  }
  .list{
    margin: 20px auto;
  }
  .quote{
    font-family: "Lato", sans-serif;
    color: rgb(109, 59, 59);
    font-style: italic;
    font-size: 18px;
    /* margin: ; */
  }
  .profile-container {
    text-align: center;
    margin-top: 2rem;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover; /* This will ensure that the image covers the area without stretching. */
  }
  
  .profile-name {
    margin-top: 8px; /* Adjust as needed for spacing */
    font-size: 1.2em; /* Adjust as needed */
    font-weight: bold; /* If you want the name to be bold */
    color: #333; /* Dark grey color, adjust as needed */
  }
  
  .table-wages {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Adjust as necessary */
  }
  .cell{
    font-weight: bold;
  }
  .table-wages td {
    padding: 8px; /* Adjust as necessary */
    border-bottom: 1px solid #ddd; /* Adjust color as necessary */
    text-align: left;
  }
  
  .table-wages tr:last-child td {
    border-bottom: none;
  }
  
  .hero {
    text-align: center;
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../../public/standardpost-img.jpg') no-repeat center center; */
    /* background-size: cover; */
    /* color: #fff; */
    font-weight: bold;
    padding: 100px 150px;
    height: 30px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .hero .author {
    font-size: 1.8rem;
    font-style: italic;
  }
  
  .content {
    padding: 20px;
    /* background: #f8f8f8; */
  }
  
  .article {
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
  }
  
  .article h2 {
    color: #333;
    font-size: 1.5rem;
  }
  
  
  /* Responsive layout adjustments */
  @media (max-width: 768px) {
    .hero {
      padding: 30px 15px;
    }
  
    .hero h1 {
      font-size: 2rem;
    }
  }
  
  /* Add more styling as per your aesthetic preference */
  /* ... existing styles ... */

/* Pagination Styles */
.ant-pagination {
    text-align: center;
    padding: 2rem;
  }
  
  /* You can customize the pagination colors, spacing, etc., to fit your design. */
  