.page-container {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .dosdonts-container {
    display: flex;
    justify-content: space-around;
  }
  
  .section-title {
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-bottom: 20px;
    font-size: 2.4rem;
    font-weight: bold;
    text-decoration: underline;

  }
  
  .icon {
    margin-right: 10px;
  }
  .dos-container{
    height: 800px; /* Adjust this value as needed */
    overflow-y: auto;
  }
  .dos-container, .donts-container {
    flex: 1;
    margin: 0 20px;
  }
  
  .dos-list, .donts-list {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dos-item, .donts-item {
    margin-bottom: 20px;
  }
  
  .item-title {
    color: #333;
    margin-bottom: 5px;
    font-size: 1.8rem;
    font-weight: bold;
  }
  