.title_download.bold {
    font-size: 3rem;
    color: black;
    margin: 2rem auto;
    text-align: center;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: rgb(211, 162, 33);
    text-decoration-thickness: 4px;
}


.nice-blue-button {
    background-color: #007bff; /* A nice shade of blue */
    color: #ffffff; /* White text color */
    border: none; /* No border */
    padding: 10px 20px; /* Top and bottom padding of 10px, left and right padding of 20px */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Change mouse cursor to pointer */
    transition: background-color 0.3s ease; /* Smooth transition for the hover effect */
    font-size: 16px; /* Text size */
    /* margin-right: 30px; */
    text-align: center; /* Center the text */
    display: inline-block; /* Allow the button to be next to other inline or inline-block elements */
    text-decoration: none; /* No underline for text */
    /* Additional styles for better appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    outline: none; /* Remove outline on focus for aesthetics */
  }
  
  .nice-blue-button:hover, .nice-blue-button:focus {
    background-color: rgb(211, 162, 33);
    color: #fff; /* Darker shade of blue on hover/focus */
  }
  