@import url('https://fonts.googleapis.com/css2?family=Labrada:wght@600&family=Poppins:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.table-data {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
}

.table-header {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: bolder;
}

.table-container {
  padding: 0 2rem;
}

.arrow-icon {
  display: inline-block;
  margin-right: 10px;
  font-size: 60px;
  /* increase font size */
  color: black;
  transition: transform 0.3s ease-in-out;
  padding: 8px;
  /* add equal padding around the icon */
}

@media (min-width: 768px) {

  /* apply padding only for screens wider than 768px */
  .arrow-icon {
    padding-left: 10px;
  }
}

.arrow-icon:hover {
  transform: translateX(-3px);
  color: #333;
}

.index-text {
  display: flex;
  align-items: center;
}

/* Media Query */
@media (min-width: 1200px) and (max-width: 1500px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 2.5rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 10rem;
    align-items: center;
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 2.2rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 7rem;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}

@media (min-width: 722px) and (max-width: 768px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}

@media (min-width: 540px) and (max-width: 721px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}

@media (min-width: 450px) and (max-width: 539px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}

@media (min-width: 397px) and (max-width: 449px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}

@media (min-width: 360px) and (max-width: 396px) {
  .table-data {
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
  }

  .table-header {
    font-family: "Montserrat", sans-serif;
    font-size: 0.9rem;
    font-weight: bolder;
  }

  .table-container {
    padding: 0 5rem;
    align-items: center;
  }
}